<template>
  <v-dialog :value="value" persistent width="800">
    <v-card color=#fafafa>
      <v-toolbar color="#1C3144">
        <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Copy - {{selectedAd.adName}}</v-toolbar-title>
        <v-spacer/>
        <v-col cols="4">
          <v-menu v-model="menu" :return-value="adDate" :close-on-content-click="false" nudge-top="25" :nudge-left="25"
            transition="scale-y-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field class="mt-6" outlined dense background-color="white" light
                :value="formattedDate" label="Ad Date" prepend-inner-icon="mdi-calendar" v-bind="attrs"
                v-on="on"/>
            </template>
            <v-date-picker :allowed-dates="allowedDates" v-model="adDate" @input="menu = false"
              :show-current="false" no-title></v-date-picker>
          </v-menu>
        </v-col>
      </v-toolbar>
      <v-data-table :items-per-page="pageSize" :height="tableSize" :headers="headers" :items.sync="destinationAds"
        class="table" fixed-header dense hide-default-footer show-select item-key="id" v-model="selected">

        <template v-slot:[`item.features`]="{ item }">
          <span>{{item.features.length}}</span>
        </template>

        <template v-slot:[`item.readyForUpload`]="{ item }">
          <v-icon :color="item.readyForUpload ? 'green' : 'red'">
            {{item.readyForUpload ? 'mdi-checkbox-marked-circle-outline' : 'mdi-close'}}
          </v-icon>
        </template>

        <template v-slot:footer>
          <v-toolbar flat color="#fafafa" class="tableFooter">
            <v-spacer/>
            <v-btn :disabled="adDate && !selected.length" height="40" color="#F9A825" class="white--text ml-2" @click="copy">
              Copy<v-icon class="ml-1" dense>mdi-content-duplicate</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { tableSettings } from '@/mixins/table'
import { notification } from '@/mixins/notification'

export default {
  name: 'AdCopy',

  mixins: [tableSettings, notification],

  props: [
    'value',
    'selectedAd',
    'getAds'
  ],

  data () {
    return {
      adDate: this.selectedAd.adDate,
      adGroups: [],
      ads: [],
      menu: false,
      selected: []
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Destination Ads', align: 'left', sortable: 'true', filterable: 'true', class: 'black-text', value: 'description' }
      ]
    },

    destinationAds () {
      let adGroupsToDisplay = []
      if (this.selectedAd.adDate == this.adDate) {
        adGroupsToDisplay = this.adGroups.filter(a => a.id !== this.selectedAd.adGroup.id)
      } else {
        adGroupsToDisplay = this.adGroups
      }
      return adGroupsToDisplay
    },

    disabled () {
      return this.adDate && this.selected.length > 0
    },

    formattedDate () {
      return this.adDate ? this.moment(this.adDate).format(this.$config.date_display) : ''
    },
    allowedDates () {
      return val => ![1, 2, 3, 4, 5, 6].includes(new Date(val).getDay())
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.getAdGroups()
          // this.getAvailableAds()
        }
      }
    }
  },

  created () {
    this.nonTableHeight = 400
  },

  methods: {
    async getAdGroups () {
      await this.$ads.getAdGroups()
        .then(response => {
          this.adGroups = response.filter(adGroup => adGroup.id !== 'ALL' &&
            adGroup.supplierCode === this.selectedAd.supplierCode
          )
        })
    },

    async getAvailableAds () {
      await this.$ads.getAds(0, 100, '', this.selectedAd.adDate)
        .then(response => {
          this.ads = response.filter(ad =>
            ad.supplierCode === this.selectedAd.supplierCode &&
            ad.adDate === this.selectedAd.adDate &&
            ad.adId !== this.selectedAd.adId &&
            ad.readyForUpload === null
          )
        })
    },

    async copy () {
      const errors = []

      this.showLoader('Copying Ads', true)

      for (const adGroup of this.selected) {
        await this.$ads.copyAd(this.selectedAd.adId, adGroup.id, this.adDate)
          .then(() => {
            this.adGroups = this._.reject(this.adGroups, { 'id': adGroup.id })
          }).catch(err => {
            try {
              errors.push(err.response.data)
            } catch {
              errors.push(`${this.selectedAd.adName} Not Copied`)
            }
          })
      }

      if (errors.length) {
        this.showErrors(errors)
      } else {
        this.notify('success', 'Ad Successfully Copied')
      }
      this.selected = []
      this.adDate = ''
      this.getAds()
    }
  }
}
</script>
